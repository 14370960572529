body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

/** WARNING - USE AT OWN RISK */
/** IT IS AGAINST APPLE'S POLICY TO USE SF PRO FOR ANYTHING OTHER THAN iOS/tvOS/macOS/watchOS DESIGN & DEVELOPMENT */
/** https://sf.abarba.me/font.css */
/** https://sf.abarba.me/LICENSE.pdf */

/** 1. Copy/import this file into your main css/scss file */
/** 2. Change css font-family: to "SF Text", "SF Display" or "SF Mono" */
/** 3. Apply font-weight or italic to html elements */

/** THANK YOU */
/** I host these fonts on Cloudfront with SSL in all AWS regions for the best performance and reliability */
/** If you'd like to help offset costs, I'd very much appreciate a donation via Lisk https://lisk.io */
/** Address: 14987768355736502769L */
/** Delegate: andrew */

/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** Black */
@font-face {
    font-family: "SF Display";
    font-weight: 900;
    src: url("https://sf.abarba.me/SF-UI-Display-Black.otf");
  }
  
  /** Bold */
  @font-face {
    font-family: "SF Display";
    font-weight: 700;
    src: url("https://sf.abarba.me/SF-UI-Display-Bold.otf");
  }
  
  /** Heavy */
  @font-face {
    font-family: "SF Display";
    font-weight: 800;
    src: url("https://sf.abarba.me/SF-UI-Display-Heavy.otf");
  }
  
  /** Light */
  @font-face {
    font-family: "SF Display";
    font-weight: 200;
    src: url("https://sf.abarba.me/SF-UI-Display-Light.otf");
  }
  
  /** Medium */
  @font-face {
    font-family: "SF Display";
    font-weight: 500;
    src: url("https://sf.abarba.me/SF-UI-Display-Medium.otf");
  }
  
  /** Regular */
  @font-face {
    font-family: "SF Display";
    font-weight: 400;
    src: url("https://sf.abarba.me/SF-UI-Display-Regular.otf");
  }
  
  /** Semibold */
  @font-face {
    font-family: "SF Display";
    font-weight: 600;
    src: url("https://sf.abarba.me/SF-UI-Display-Semibold.otf");
  }
  
  /** Thin */
  @font-face {
    font-family: "SF Display";
    font-weight: 300;
    src: url("https://sf.abarba.me/SF-UI-Display-Thin.otf");
  }
  
  /** Ultralight */
  @font-face {
    font-family: "SF Display";
    font-weight: 100;
    src: url("https://sf.abarba.me/SF-UI-Display-Ultralight.otf");
  }

.App {
    background-image: url("./background.svg");
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-gap: 10px;
    height: 100vh;
    margin: 4px;
}

.container {
    grid-column: 2 / 5;
    grid-row: 3/5;
}

.title {
    font-size: 100px;
    font-weight: 900;
    color: #191919;
    font-family: "SF Display";
}

.subtitle {
    font-family: "SF Display";
    font-weight: 600;
    font-size: 40px;
    color: #191919;
}

.counter {
    font-family: "SF Display";
    font-weight: 400;
    font-size: 24px;
    color: #191919;
}

.nb {
    color: rgb(236, 86, 132);
    font-weight: 800;
}

.buttonBlock {
    margin-top: 40px;
    width: 400px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 16px;
}

button {
    width: 208px;
    height: 62px;
    border: none;
    border-radius: 8px;
    font-size: 24px;
    font-family: "SF Display";
    font-weight: 700;
    line-height: 32px;
    transition-duration: 0.24s;
}

button:hover{
    cursor: pointer;
    transform: translateY(-2px); 
    transition-duration: 0.08s;
}   

button:active{
    transform: translateY(0);
    box-shadow: 0 4px 8px -4px rgba(242, 99, 66, 0);
}

button:focus {outline:0;}

.btnNew {
    color: white;
    background: linear-gradient(90deg, rgb(236, 86, 132) 0%, rgb(242, 99, 66) 100%);
    box-shadow: 0 4px 8px -4px rgba(242, 99, 66, 1);
}

.btnNew :hover{
    box-shadow: 0 6px 8px -4px rgba(242, 99, 66, 0.8);
}

.btnClose {
    border-radius: 8px;
    color: rgb(236, 86, 132);
    background-color: white;
    border: solid 1px rgba(236, 86, 132, 1);
}


.btnClose :active{
    box-shadow: 2px 1000px 1px #fff inset;
}

.footer {
    grid-column: 2;
    grid-row: 7;
    text-transform: uppercase;
    font-family: "SF Display";
    font-weight: 700;
    font-size: 12px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* MEDIA QUERIES - RESPONSIV-ISH-NESS */

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* smartphone en mode portait */

    .App {
        background-image: url("./background.svg");
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: repeat(10, 1fr);
        grid-gap: 8px;
        margin: 4px;
        overflow: hidden;
    }

    .container {
        grid-column-start: 2;
        grid-column-end: 10;
        grid-row-start: 2;
        grid-row-end: 6;
    }

    .buttonBlock {
        display: flex;
        flex-direction: column;
    }

    .btnNew{margin-bottom: 16px;}

    .title {
        font-size: 64px;
        line-height: 64px;
        font-weight: 900;
        margin-bottom: 16px;
        width: fit-content;
        color: #191919;
        font-family: "SF Display";
    }

    .subtitle {
        font-family: "SF Display";
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 8px;
        color: #191919;
    }

    .counter {
        font-family: "SF Display";
        font-weight: 400;
        font-size: 14px;
        color: #191919;
    }

    .footer {
        grid-column-start:2;
        grid-column-end: 8;
        grid-row: 10;
        text-transform: uppercase;
        font-family: "SF Display";
        font-weight: 700;
        font-size: 12px;
    }
}
    